import React from 'react'
import { useSelector } from 'react-redux'

import { Capacitor } from '@capacitor/core'
import PropTypes from 'prop-types'

import DesktopView from './Desktop.view'
import MobileView from './Mobile.view'

function BaseHeader({
  showCollapse = true,
  showUnitSelector = true,
  showThemeSelector = true,
  showLogbook = true,
  showAlert = true,
  showUserProfile = true,
  showLogout = true,
}) {
  const user = useSelector(state => state.user.profile)

  return Capacitor.isNativePlatform() || window._env_?.CAPACITOR_DEBUG ? (
    <MobileView
      showCollapse={showCollapse}
      showUnitSelector={showUnitSelector}
      showThemeSelector={showThemeSelector}
      showLogbook={showLogbook}
      showAlert={showAlert}
      showUserProfile={showUserProfile}
      showLogout={showLogout}
      user={user}
    />
  ) : (
    <DesktopView
      showCollapse={showCollapse}
      showUnitSelector={showUnitSelector}
      showThemeSelector={showThemeSelector}
      showLogbook={showLogbook}
      showAlert={showAlert}
      showUserProfile={showUserProfile}
      showLogout={showLogout}
      user={user}
    />
  )
}

BaseHeader.defaultProps = {
  showAlert: true,
  showCollapse: true,
  showLogbook: true,
  showLogout: true,
  showThemeSelector: true,
  showUnitSelector: true,
  showUserProfile: true,
}

BaseHeader.propTypes = {
  showAlert: PropTypes.bool,
  showCollapse: PropTypes.bool,
  showLogbook: PropTypes.bool,
  showLogout: PropTypes.bool,
  showThemeSelector: PropTypes.bool,
  showUnitSelector: PropTypes.bool,
  showUserProfile: PropTypes.bool,
}

export default BaseHeader
